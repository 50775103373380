/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layouts/layout';
import SEO from '../../components/seo';
import BlogPagination from '../../components/blog/blog-pagination';
import Articles from '../../components/blog/articles';

export const query = graphql`
  query GetBlogPageSettingsWithLocale($locale: String!, $skip: Int!, $limit: Int!) {
    pageSettings: contentfulBlogPageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords
      mainHeading
      categoriesText
      shareCalloutText
      paginationPrevious
      paginationNext
    }

    articles: allContentfulBlogArticle(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        excerpt {
          excerpt
        }
        categories {
          title
          slug
        }
        author {
          firstName
          lastName
          slug
          avatar {
            resize(width: 100, height: 100) {
              src
            }
          }
        }
      }
    }
  }
`;

function BlogPage({ data, pageContext }) {
  const { pageSettings, articles } = data;
  const { numPages, currentPage } = pageContext;
  return (
    <Layout>
      <SEO
        title={pageSettings.title}
        description={pageSettings.description.description}
        keywords={pageSettings.keywords}
      />
      <section
        className="flex flex-col max-w-4xl w-full m-auto"
        itemScope
        itemType="https://schema.org/Blog"
      >
        <header className="mt-8 leading-loose">
          <h1
            className="font-extrabold md:text-5xl text-left"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: pageSettings.mainHeading,
            }}
          />
          <p className="text-lg md:text-2xl text-gray-700">
            <b>Learn about remote work</b>, read first about new <b>platform updates</b> and stay in
            the loop with remote work news.{' '}
            <strong>Strong views on remote work, management and company culture.</strong>
          </p>
        </header>
        <main className="leading-normal">
          <Articles articles={articles.nodes} pageSettings={pageSettings} />
        </main>
        <BlogPagination numPages={numPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}

export default BlogPage;
