import React from 'react';
import { Link } from 'gatsby';

const Meta = ({ author, categories }) => (
  <div className="article-meta" itemScope itemType="https://schema.org/Person">
    <div className="separator">
      <img
        alt="author avatar"
        className="block m-auto rounded-full align-middle w-26 border-2 border-gray-300"
        src={author.avatar.resize.src}
        itemProp="image"
      />
    </div>
    <Link itemProp="url" to={`/blog/author/${author.slug}`} className="text-theme-purple">
      <span itemProp="name">{`${author.firstName} ${author.lastName}`}</span>
    </Link>{' '}
    /{' '}
    {categories.map((c, i, { length }) => (
      <>
        <Link className="text-theme-purple uppercase" to={`/blog/category/${c.slug}`}>
          {c.title}
        </Link>
        {length - i > 1 ? ' / ' : ''}
      </>
    ))}
  </div>
);

export default Meta;
