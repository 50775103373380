import React from 'react';
import { Link } from 'gatsby';
import Meta from './meta';

const Articles = ({ articles }) =>
  articles &&
  articles.map(article => (
    <article className="blog-article" itemProp="blogPost">
      <header>
        <Meta author={article.author} categories={article.categories} />
        <h1>
          <Link
            className="no-underline text-gray-900 hover:text-theme-purple"
            to={`/blog/${article.slug}`}
          >
            {article.title}
          </Link>
        </h1>
      </header>
      <main
        className="prose lg:prose-xl prose-theme-purple my-4 w-full"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: article.excerpt.excerpt,
        }}
      />
      <footer className="text-left font-bold uppercase tracking-wide text-base">
        <Link className="text-theme-purple" to={`/blog/${article.slug}`} title="Continue reading">
          Continue reading
        </Link>
      </footer>
    </article>
  ));

export default Articles;
