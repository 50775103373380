import React from 'react';
import { Link } from 'gatsby';

const BlogPagination = ({ numPages, currentPage }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `/blog` : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;

  return (
    <div className="mt-3 flex flex-wrap justify-between align-center">
      {!isFirst ? (
        <Link className="text-theme-purple font-bold" to={prevPage} rel="prev">
          &larr; Previous Page
        </Link>
      ) : (
        <span />
      )}
      {!isLast ? (
        <Link className="text-theme-purple font-bold" to={nextPage} rel="next">
          Next Page &rarr;
        </Link>
      ) : (
        <span />
      )}
    </div>
  );
};
export default BlogPagination;
